<script setup lang="ts">
import type { BlogPost } from '~/api/types/generated/BlogPost'

defineProps<{
  blogPosts: BlogPost[]
  id: string
  title: string
}>()

const emit = defineEmits<{
  dataLayerUpdate: [boolean]
}>()

function handleDataLayerUpdate() {
  emit('dataLayerUpdate', true)
}
</script>

<template>
  <BaseSectionBox :id="id" :title="title">
    <ul class="flex flex-col gap-y-3">
      <li
        v-for="post in blogPosts"
        :key="post.id"
      >
        <BlogCard
          :author="post.attributes.author?.data"
          :created-at="new Date(post.attributes.createdAt)"
          :description="post.attributes.description"
          :poster="post.attributes.poster?.data"
          :slug="post.attributes.slug"
          :title="post.attributes.title"
          title-level="h3"
          @data-layer-update="handleDataLayerUpdate"
        />
      </li>
    </ul>
  </BaseSectionBox>
</template>
