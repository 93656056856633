import { getAbbreviationNumber } from '@cfgtech/helpers'
import dayjs from 'dayjs'
import { range, rangeRight } from 'lodash-es'
import type { FinancialStatements } from '~~/../admin/types/components/FinancialStatements'

function getLatestMissingYear(latestYearWithData: number): number | null {
  const currentYear = dayjs().subtract(2, 'year').year()
  const missingYear = rangeRight(latestYearWithData, currentYear + 1).find(year =>
    dayjs().isAfter(dayjs(year).endOf('year')),
  )

  return missingYear || null
}

export function axisLabelFormatter(val: number): string {
  if (Number.isNaN(val)) {
    return '-'
  }

  const { value, suffixKey } = getAbbreviationNumber(val)
  return `${Intl.NumberFormat('cs-CZ').format(value)} ${suffixKey}`
}

export function fillMissingYears(years: string[]): number[] {
  const numYears = years.map(Number)
  const minYear = Math.min(...numYears)
  const maxYear = getLatestMissingYear(Math.max(...numYears))

  if (!maxYear) {
    return numYears.sort()
  }

  return range(minYear, maxYear + 1)
}

export type FinancialData = FinancialStatements

export const createEmptyDataObject: Partial<FinancialData> = {
  longtermAssets: Number.NaN,
  currentAssets: Number.NaN,
  otherTimeresolvedAssets: Number.NaN,
  otherLiabilities: Number.NaN,
  revenues: Number.NaN,
  equity: Number.NaN,
  foreignResources: Number.NaN,
  debt: Number.NaN,
}

// CHART PADDING GETTERS
function getAdjustedBoundary(value: number, isMax: boolean) {
  const paddingThreshold = 300

  if (Math.abs(value) < paddingThreshold) {
    return isMax ? (value < 0 ? 0 : paddingThreshold) : (value >= 0 ? 0 : -paddingThreshold)
  }

  return undefined
}

export const getChartMax = ({ max }: { max: number }) => getAdjustedBoundary(max, true)

export const getChartMin = ({ min }: { min: number }) => getAdjustedBoundary(min, false)
