<script lang="ts" setup>
const props = defineProps<{ markdown: string }>()

function sanitize(html: string): string {
  return html.replace(/h1>/g, 'h2>')
}

const resultHTML = computed(() => sanitize(props.markdown || ''))
</script>

<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <div v-html="resultHTML" />
</template>
