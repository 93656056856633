import { useDateFormat } from '@vueuse/core'
import type { CfgTableDataSource } from '@cfgtech/ui'
import type { Ownerships } from '~~/../admin/types/components/Ownerships'
import type { Company, Row } from '@/api/types'
import { APP_DATE_FORMAT } from '~/constants/common'
import { CzNameTypesTranslation } from '~/api/modules/issues/services/getIssuesList/getIssuesList.types'

export function useCompanyContent() {
  const responsive = useResponsive()
  const { t } = useI18n()

  const baseHeaders = computed<CfgTableDataSource<Row>['headers']>(() => [
    { keyName: 'key', text: '', width: responsive.value.mobile === false ? '200px' : '50%' }, // Headers are not displayed
    { keyName: 'value', text: '', width: responsive.value.mobile === false ? '350px' : '50%' }, // Headers are not displayed
  ])
  function companyBaseInfo(company: Company): CfgTableDataSource<Row> {
    const businessType = company?.identification?.businessTypeByCzNace?.toLowerCase() as keyof typeof CzNameTypesTranslation

    const companyBaseInfoDataSource: CfgTableDataSource<Row> = {
      headers: baseHeaders.value,

      data: [
        { key: t('company.identification.name'), value: company?.name || '-' },
        { key: t('company.identification.startDate'), value: company?.identification?.startDate ? useDateFormat(company?.identification.startDate, APP_DATE_FORMAT).value : '-' },
        { key: t('company.identification.legalForm'), value: company?.identification?.legalForm || '-' },
        { key: t('company.identification.ico'), value: company?.companyId || '-' },
        {
          key: t('company.identification.businessTypeByCzNace'),
          value: businessType && Object.keys(CzNameTypesTranslation).includes(businessType)
            ? `${businessType.toUpperCase()} - ${CzNameTypesTranslation[businessType]}`
            : '-',
        },
      ],
    }
    return companyBaseInfoDataSource
  }

  function companyContactTable(company: Company, isContactIssuerDialogOpened: Ref<boolean>) {
    const contactTable = computed<CfgTableDataSource<Row>>(() => ({
      headers: baseHeaders.value,
      data: [
        { key: t('company.contact.fullAddress'), value: company?.contact?.fullAddress || '-' },
        {
          key: t('company.contact.phoneAndEmail'),
          value: `<a href="#" class="hover:cursor-pointer underline text-brand"> ${t('company.contact.getTrustedContact')} </a>`,
          meta: {
            onClick() {
              isContactIssuerDialogOpened.value = true
            },
          },
        },
      ],
    }))
    return contactTable
  }

  function companyStatutoryTable(company: Company) {
    const companyStatutoryTable = computed(() => ({
      headers: baseHeaders.value,
      data: company?.statutory?.map(statutory => ({
        key: statutory.type,
        value: {
          name: statutory.name,
          startDate: statutory.startDate && t('common.date.from', { from: useDateFormat(statutory.startDate, APP_DATE_FORMAT).value }),
        },
      })) || [],
    }))
    return companyStatutoryTable
  }

  function buildOwnershipsTable(ownerships: Ownerships[]): CfgTableDataSource<{
    key: string
    value: {
      commonInformation: string
      valuePercent?: string
      startDate?: string
      endDate?: string
    }
  }> {
    return {
      headers: baseHeaders.value,
      data: ownerships.map(ownership => ({
        key: ownership.type || '',
        value: {
          commonInformation: [ownership.name, ownership.address, ownership.country].join(', '),
          valuePercent: ownership.valuePercent ? t('company.ownerships.valuePercent', { percent: ownership.valuePercent }) : '',
          startDate: ownership.startDate && t('common.date.from', { from: useDateFormat(ownership.startDate, APP_DATE_FORMAT).value }),
          endDate: ownership.endDate && t('common.date.to', { to: useDateFormat(ownership.endDate, APP_DATE_FORMAT).value }),
        },
      })) || [],
    }
  }

  return {
    companyBaseInfo,
    companyStatutoryTable,
    companyContactTable,
    buildOwnershipsTable,
  }
}
