<script setup lang="ts">
import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import type { ECBasicOption } from 'echarts/types/dist/shared'
import type { FinancialStatements } from '~/api/types'

const props = defineProps<{
  financialStatements: FinancialStatements[]
  description?: string
}>()

const [
  { LineChart, BarChart },
  { TooltipComponent, AxisPointerComponent, LegendComponent, GridComponent },
  { CanvasRenderer },
  { use },
  { default: VChart, THEME_KEY },
] = await Promise.all([
  await import('echarts/charts'),
  await import('echarts/components'),
  await import('echarts/renderers'),
  await import('echarts/core'),
  await import('vue-echarts'),
])

use([
  LineChart,
  BarChart,
  TooltipComponent,
  AxisPointerComponent,
  LegendComponent,
  CanvasRenderer,
  GridComponent,
])

provide(THEME_KEY, 'light')

const { economicPreviewTable, createBarChartOptions, createLineChartOptions } = useCompanyEconomicResults({
  financialStatements: props.financialStatements || [],
})

const overviewTypes = computed<{ type: string, chartData: ECBasicOption | null }[]>(() => ([
  {
    type: 'sales',
    chartData: createLineChartOptions('revenues'),
  },
  {
    type: 'profit',
    chartData: createLineChartOptions('incomeTradingBeforeTax'),
  },
  {
    type: 'assets',
    chartData: createBarChartOptions(['longtermAssets', 'currentAssets', 'otherTimeresolvedAssets']),
  },
  {
    type: 'passives',
    chartData: createBarChartOptions(['equity', 'foreignResources', 'otherLiabilities']),
  },
  {
    type: 'equity',
    chartData: createLineChartOptions('equity'),
  },
  {
    type: 'debt',
    chartData: createLineChartOptions('debt'),
  },
]))

const vcharts = ref<null | any[]>(null)

function hideTooltipByOverviewType(overviewType: string) {
  if (!vcharts.value) {
    return
  };

  const foundChart = vcharts.value.find(
    el => el.$attrs['data-overview-type'] === overviewType,
  )

  foundChart?.dispatchAction({
    type: 'hideTip',
  })
}
</script>

<template>
  <div class="flex flex-col gap-y-8">
    <CfgTypography
      v-if="description"
      :size="CfgTypographySizes.md"
      tag-name="p"
    >
      <span v-html="description" />
    </CfgTypography>

    <CompanyDetailsEconomicResultsSummaryTable
      class="w-full"
      :economic-preview-table="economicPreviewTable"
    />

    <div>
      <CfgTypography :size="CfgTypographySizes.h3">
        {{ $t('company.economicResults.detail.detailedOverviews.title') }}
      </CfgTypography>

      <div
        v-for="overviewType in overviewTypes"
        :key="overviewType.type"
        class="max-w-1/2 mt-8 flex flex-col gap-y-7"
      >
        <div class="space-y-3">
          <CfgTypography class="block" :size="CfgTypographySizes.h4">
            <span v-html="$t(`company.economicResults.detail.detailedOverviews.${overviewType.type}.title`)" />
          </CfgTypography>

          <CfgTypography :size="CfgTypographySizes.md" tag-name="p">
            {{ $t(`company.economicResults.detail.detailedOverviews.${overviewType.type}.description`) }}
          </CfgTypography>
        </div>

        <ClientOnly
          v-if="overviewType.chartData"
          :fallback="$t('company.economicResults.chartLoading')"
        >
          <div @mouseleave="hideTooltipByOverviewType(overviewType.type)">
            <VChart
              ref="vcharts"
              class="h-[250px] w-full"
              :data-overview-type="overviewType.type"
              :option="overviewType.chartData"
            />
          </div>
        </ClientOnly>

        <BaseAlert v-else variant="info">
          <CfgTypography :size="CfgTypographySizes.md">
            {{ $t('company.economicResults.noDataAvailable.title') }}
          </CfgTypography>
        </BaseAlert>
      </div>
    </div>
  </div>
</template>
