<script setup lang="ts">
import { CfgHint } from '@cfgtech/ui'

defineProps<{
  economicPreviewTable: {
    head: string[]
    items: string[][]
  }
}>()

// methods
function convertToInteger(value: string): number {
  if (value === '-') {
    return 0
  }

  return Number.parseFloat(value.replace(/\s/g, ''))
}
</script>

<template>
  <div class="styled-text prose">
    <table class="w-full">
      <!-- TODO: REFACTOR WITH CFG TABLE     -->
      <thead class="border-b-[color:var(--tw-prose-td-borders)]">
        <tr>
          <th
            v-for="(item, index) in economicPreviewTable.head"
            :key="index"
            class="font-normal"
            :class="{
              '!font-highlighted text-black': index === 0,
              'text-right': index !== 0,
            }"
            scope="col"
          >
            {{ item }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="row in economicPreviewTable.items" :key="row[0]">
          <td
            v-for="(col, index) in row"
            :key="index"
            :class="{
              'font-highlighted text-black': index === 0,
              'text-right': index !== 0,
              'text-success': index === row.length - 1 && convertToInteger(col) >= 0 && col !== '-',
              'text-red': index === row.length - 1 && convertToInteger(col) < 0 && col !== '-',
            }"
          >
            <span v-html="col" />

            <CfgHint v-if="col === '0' || col === '-'" class="-mb-1 ml-1.5">
              <template #content>
                {{ $t('company.economicResults.noDataAvailable.title') }}
              </template>
            </CfgHint>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
