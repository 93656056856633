<script setup lang="ts">
import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import type { Company } from '~/api/types'
import type { IssueBuyLocations } from '~/components/Issue/types'

const props = defineProps<{
  company: Company | null
  id: string
  sidesheetTitle?: string
  sidesheetCaption?: string
  isDataAvailable: boolean
}>()

defineEmits<{
  'open:sidesheet': []
  'close:sidesheet': []
  'buy:issue': [location: IssueBuyLocations]
}>()

const economicResultsData = computed(() => props.company?.financialResults)

const financialStatements = computed(() => economicResultsData.value?.statements || [])

const { economicPreviewTable } = useCompanyEconomicResults({
  financialStatements: financialStatements.value,
})
</script>

<template>
  <BaseSectionBox
    :id="id"
    :button="{
      label: $t('company.economicResults.preview.showMore'),
      isVisible: isDataAvailable,
    }"
    :sidesheet-caption="sidesheetCaption || ''"
    :sidesheet-title="sidesheetTitle || ''"
    :title="$t('company.economicResults.title')"
    @buy:issue="$emit('buy:issue', $event)"
    @close:sidesheet="$emit('close:sidesheet')"
    @open:sidesheet="$emit('open:sidesheet')"
  >
    <div v-if="isDataAvailable">
      <!--  COMPANY EXTENDED DETAIL SHORT DESCRIPTION  -->

      <TypographyStyledText v-if="economicResultsData?.shortDescription">
        <CoreMarkdownRenderer class="font-display text-grey-400" :markdown="economicResultsData.shortDescription || ''" />
      </TypographyStyledText>

      <div class="flex flex-col">
        <CompanyDetailsEconomicResultsSummaryTable class="w-full" :economic-preview-table="economicPreviewTable" />
      </div>
    </div>

    <div v-else>
      <CfgTypography :size="CfgTypographySizes.md" tag-name="p">
        {{ $t('company.economicResults.noDataAvailable.title') }}
      </CfgTypography>
    </div>

    <!-- SIDESHEET CONTENT -->
    <template #sidesheet:content>
      <div>
        <TypographyStyledText v-if="economicResultsData?.description">
          <CoreMarkdownRenderer class="font-display text-grey-400" :markdown="economicResultsData.description" />
        </TypographyStyledText>
      </div>

      <CompanyDetailsEconomicResultsDetails :financial-statements="financialStatements" />
    </template>
  </BaseSectionBox>
</template>
