<script setup lang="ts">
import { CfgButton, CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import type { SectionBoxExposed } from './SectionBox.types'
import { type IssueBuyLocations, IssueBuyUrlTypeEnum } from '~/components/Issue/types'

const props = defineProps<{
  /**
   * The id of the section.
   * Will be used to identify the section in the page and as hash in the url to open the section.
   */
  id: string

  /**
   * The title of the section.
   */
  title?: string

  /**
   * Caption to be displayed below the title.
   */
  caption?: string

  /**
   * The button to be displayed in the footer of the section.
   * If not provided, no button will be displayed.
   */
  button?: {
    label: string
    disabled?: boolean
    loading?: boolean
    isVisible?: boolean
  }

  /**
   * The title content of the sidesheet.
   */
  sidesheetTitle?: string

  /**
   * The caption content of the sidesheet.
   */
  sidesheetCaption?: string
}>()

const emit = defineEmits<{
  'close:sidesheet': []
  'open:sidesheet': []
  'buy:issue': [location: IssueBuyLocations]
}>()

defineSlots<{
  /**
   * Render main content of the section in page.
   */
  'default': () => any

  /**
   * Allow replacing the title content with a custom one.
   */
  'title'?: (props: { text: string }) => any

  /**
   * Render content of sidesheet.
   */
  'sidesheet:content'?: (props: { isOpen: boolean, close: SectionBoxExposed['close'] }) => any

  /**
   * Allow replacing the button content with a custom one.
   */
  'button'?: (props: { disabled?: boolean, loading?: boolean }) => any

  /**
   * Allows setting a custom icon for the button.
   */
  'button:icon'?: (props: { disabled?: boolean, loading?: boolean }) => any
}>()

const responsive = useResponsive()

const { width: screenWidth } = useWindowSize()

const isSidesheetOpen = ref(false)

const component = computedAsync(async () => {
  if (process.server) {
    return ''
  }

  return responsive.value.mobile
    ? (await import('@cfgtech/ui')).CfgBottomSheet
    : (await import('@cfgtech/ui')).CfgSlideOver
}, '')

const boxRef = ref<HTMLElement | null>(null)

const { height: boxRefHeight } = useElementBounding(boxRef)

const MIN_VISIBILITY_PX = 350
const MIN_THREASHOLD = 0.7

const threshold = computed(() => {
  if (boxRefHeight.value <= MIN_VISIBILITY_PX) {
    return MIN_THREASHOLD
  }

  const visibility = boxRefHeight.value / MIN_VISIBILITY_PX / 10

  // IntersectionObserver cannot handle values above 1
  if (visibility > 1) {
    return MIN_VISIBILITY_PX / boxRefHeight.value
  }

  return visibility
})

const orderURL = inject('orderURL', '')
const orderURLType = inject('orderURLType', IssueBuyUrlTypeEnum.JAMES)
const handleOnViewport = inject<((_id: string, _isVisible: boolean) => void) | null>('handleOnViewport', null)

const hash = computed(() => `${props.id}-content`)

let observer: IntersectionObserver | null = null

const handleIntersecting: IntersectionObserverCallback = ([{ isIntersecting }]) => {
  if (handleOnViewport) {
    handleOnViewport(props.id, isIntersecting)
  }
}

const appStore = useAppStore()

onMounted(() => {
  if (window.location.hash === `#${hash.value}`) {
    handleSidesheetOpen()
  }

  watch(threshold, () => {
    if (observer) {
      observer.disconnect()
    }

    if (boxRef.value && handleOnViewport) {
      observer = new IntersectionObserver(handleIntersecting, {
        threshold: threshold.value,
      })

      observer.observe(boxRef.value)
    }
  }, { immediate: true })
})

onBeforeUnmount(() => {
  if (observer) {
    observer.disconnect()
  }
})

defineExpose({
  close: handleSidesheetClose,
} satisfies SectionBoxExposed)

function handleSidesheetOpen() {
  emit('open:sidesheet')
  isSidesheetOpen.value = true
}

function handleSidesheetClose() {
  if (!appStore.isSidesheetCloseAllowed) {
    return
  }

  emit('close:sidesheet')
  isSidesheetOpen.value = false
}

function handleBuyIssue() {
  handleSidesheetClose()
  emit('buy:issue', DataLayerLocationsEnum.Floating)
}

watch(isSidesheetOpen, () => {
  if (isSidesheetOpen.value) {
    window.location.hash = hash.value
    return
  }

  if ('pushState' in history) {
    history.replaceState('', document.title, window.location.pathname + window.location.search)
  }
  else {
    const scrollV = document.body.scrollTop

    window.location.hash = ''

    document.body.scrollTop = scrollV
  }
})
</script>

<template>
  <div :id="id" ref="boxRef">
    <header v-if="title">
      <CfgTypography :size="CfgTypographySizes.h2" tag-name="h2">
        <span>
          <slot name="title" :text="title">
            <span v-html="title" />
          </slot>
        </span>

        <CfgTypography
          v-if="caption"
          class="block text-grey-300"
          :size="CfgTypographySizes.sm"
          tag-name="span"
        >
          <span v-html="caption" />
        </CfgTypography>
      </CfgTypography>
    </header>

    <div class="mt-4">
      <slot />
    </div>

    <footer v-if="button && button.isVisible !== false" class="pt-8">
      <div class="w-full max-w-[494px]">
        <CfgButton
          class="w-full"
          :disabled="button.disabled"
          :label="button.label"
          :loading="button.loading"
          stroke
          @click="handleSidesheetOpen"
        >
          <slot
            :disabled="button.disabled"
            :loading="button.loading"
            name="button"
          />

          <template #icon>
            <slot name="button:icon" />
          </template>
        </CfgButton>
      </div>
    </footer>
  </div>

  <ClientOnly>
    <Component
      :is="component"
      v-if="component && $slots['sidesheet:content']"
      closable
      :description="sidesheetCaption || caption"
      :opened="isSidesheetOpen"
      :title="sidesheetTitle || title"
      v-bind="{
        ...(!responsive.mobile ? { width: screenWidth < 800 ? '80vw' : '50vw' } : {}),
      }"
      @update:opened="handleSidesheetClose"
    >
      <div class="w-full max-w-[765px] pb-20 pt-5">
        <slot
          :close="handleSidesheetClose"
          :is-open="isSidesheetOpen"
          name="sidesheet:content"
        />

        <IssueDetailsV2OrderOnlineButton
          v-if="orderURL"
          class="left-2 right-36 min-[400px]:left-auto lg:right-44"
          :order-url-type="orderURLType"
          @click="handleBuyIssue"
        />
      </div>
    </Component>
  </ClientOnly>
</template>
