import type { ECBasicOption } from 'echarts/types/dist/shared'
import type { FinancialData } from './helpers'
import { axisLabelFormatter, createEmptyDataObject, fillMissingYears, getChartMax, getChartMin } from './helpers'
import type { FinancialStatements } from '~/api/types'

type CompanyEconomicResults = {
  financialStatements: FinancialStatements[]
}

const defaultColors = [
  '#2196F3',
  '#9768f4',
  '#FFC107',
  '#FF5722',
  '#F44336',
  '#4CAF50',
]

export function useCompanyEconomicResults({ financialStatements }: CompanyEconomicResults, colors = defaultColors) {
  const responsive = useResponsive()

  // composables
  const { t } = useI18n()

  // computeds
  const companyEconomicResults = computed(() => {
    const keyedData = financialStatements.reduce((acc, record) => {
      const year = Number(record.year)
      if (!acc[year]) {
        acc[year] = { ...createEmptyDataObject }
      }
      acc[year] = { ...acc[year], ...record }
      return acc
    }, {} as Record<number, Partial<FinancialStatements>>)

    const years = fillMissingYears(Object.keys(keyedData)) satisfies number[]

    return Object.fromEntries(years.map((year: number) => {
      const data = { ...createEmptyDataObject, ...keyedData[year] }
      delete data.id
      delete data.year

      return [year, Object.fromEntries(Object.entries(data).map(([key, value]) => [key, value === null ? Number.NaN : Number(value)]))]
    }))
  })

  const economicPreviewTable = computed(() => {
    const twoLastYears = Object.values(companyEconomicResults.value).slice(-2)

    const calculatePercentageDifference = (a: number, b: number) => {
      if (Number.isNaN(a) || Number.isNaN(b)) {
        return '-'
      }

      const value = ((a - b) / Math.abs(b)) * 100

      if (Number.isNaN(value) || !Number.isFinite(value)) {
        return '-'
      }

      const sign = value >= 0 ? '+' : '-'
      const formattedValue = Intl.NumberFormat('cs-CZ').format(Number(Math.abs(value).toFixed(2)))

      return `${sign} ${formattedValue} %`
    }

    const isValidNumber = (value: string | number) => !Number.isNaN(Number(value)) && Number.isFinite(Number(value))

    const indicators = [
      {
        label: 'company.economicResults.summary.revenues',
        value: (data: FinancialData) => Number(data?.revenues),
        format: (data: FinancialData) => !Number.isNaN(Number(data?.revenues)) ? `${axisLabelFormatter(Number(data?.revenues))} Kč` : '-',
      },
      {
        label: 'company.economicResults.summary.operatingResult',
        value: (data: FinancialData) => Number(data?.incomeTradingBeforeTax),
        format: (data: FinancialData) => !Number.isNaN(Number(data?.incomeTradingBeforeTax)) ? `${axisLabelFormatter(Number(data?.incomeTradingBeforeTax))} Kč` : '-',
      },
      {
        label: 'company.economicResults.summary.assets',
        value: (data: FinancialData) => !Number.isNaN(Number(data?.assets)) ? Number(data?.assets) : Number.NaN,
        format: (data: FinancialData) => !Number.isNaN(Number(data?.assets)) ? `${axisLabelFormatter(Number(data?.assets))} Kč` : '-',
      },
      {
        label: 'company.economicResults.summary.equity',
        value: (data: FinancialData) => Number(data?.equity),
        format: (data: FinancialData) => !Number.isNaN(Number(data?.equity)) ? `${axisLabelFormatter(Number(data?.equity))} Kč` : '-',
      },
      // {
      //   label: 'company.economicResults.summary.debt',
      //   value: (data: any) => data.debt,
      //   format: (data: any) => !isNaN(data.debt) ? `${data.debt} %` : '-',
      // },
    ]

    return {
      head: [
        t('company.economicResults.summary.keyIndicators'),
        ...Object.keys(companyEconomicResults.value).slice(-2),
        t('company.economicResults.summary.yoy'),
      ],

      items: indicators.map((indicator) => {
        return [
          t(indicator.label),

          ...twoLastYears.map(data => indicator.format(data)),

          isValidNumber(indicator.value(twoLastYears[0])) && isValidNumber(indicator.value(twoLastYears[1]))
            ? calculatePercentageDifference(indicator.value(twoLastYears[1]), indicator.value(twoLastYears[0]))
            : '-',
        ]
      }),
    }
  })

  function getChartUnit(key: keyof typeof companyEconomicResults.value[number]) {
    if (key === 'debt') {
      return '%'
    }

    return 'Kč'
  }

  function createBarChartOptions(fields: (keyof typeof companyEconomicResults.value[number])[]): ECBasicOption | null {
    const years = Object.keys(companyEconomicResults.value).map(Number)

    if (!years || fields.every(field => years.every(year => (companyEconomicResults.value[year][field] !== 0 && !companyEconomicResults.value[year][field])))) {
      return null
    }

    const seriesData = fields.map((field, index) => {
      const color = colors[index % colors.length]

      return {
        name: t(`company.economicResults.fields.${field}`),
        type: 'bar',
        stack: 'total',

        emphasis: {
          focus: 'series',
        },

        itemStyle: { color },
        data: years.map(year => Number.isNaN(Number(companyEconomicResults.value?.[year]?.[field])) ? '-' : Number(companyEconomicResults.value?.[year]?.[field])),
      }
    })

    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        appendToBody: !responsive.value.mobile,
        confine: responsive.value.mobile,
        valueFormatter: (value: number | string) => {
          return isNumber(value) ? `${axisLabelFormatter(Number(value))} Kč` : t('company.economicResults.noDataAvailable.title')
        },
      },

      legend: {
        data: fields,
      },

      grid: {
        left: '0',
        right: '4%',
        top: '5%',
        bottom: '3%',
        height: 'auto',
        containLabel: true,
      },

      yAxis: {
        type: 'value',
        name: 'Částka v tis. Kč',
        min: getChartMin,
        max: getChartMax,
        axisLabel: {
          formatter: axisLabelFormatter,
          align: 'right',
          margin: 5,
          padding: [0, -5, 0, 0],
        },
      },

      xAxis: {
        type: 'category',
        data: years,
        align: 'left',
      },

      series: seriesData,
    }
  }

  function createLineChartOptions(field: keyof typeof companyEconomicResults.value[number]): ECBasicOption | null {
    if (Object.values(companyEconomicResults.value).every(year =>
      isNil(year[field]) || Number.isNaN(year[field]) || !Number.isFinite(year[field]),
    )) {
      return null
    }

    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        appendToBody: !responsive.value.mobile,
        confine: responsive.value.mobile,
        valueFormatter: (value: number | string) => {
          return isNumber(value) ? `${axisLabelFormatter(value)} ${getChartUnit(field)}` : t('company.economicResults.noDataAvailable.title')
        },
      },

      grid: {
        left: '1%',
        right: '4%',
        top: '5%',
        bottom: '3%',
        height: 'auto',
        containLabel: true,
      },

      textStyle: {
        fontFamily: 'Gilroy',
        fontSize: 10,
      },

      xAxis: {
        type: 'category',
        data: Object.keys(companyEconomicResults.value),
      },

      yAxis: {
        type: 'value',
        min: getChartMin,
        max: getChartMax,
        axisLabel: {
          align: 'right',
          formatter: axisLabelFormatter,
          margin: 5,
          padding: [0, -5, 0, 0],
        },
      },

      series: [
        {
          data: Object.values(companyEconomicResults.value).map(year => Number.isNaN(Number(year[field])) ? '-' : Number(year[field])),
          itemStyle: { color: colors?.[1] || colors[0] },
          type: 'line',
          symbolSize: 8,
        },
      ],
    } satisfies ECBasicOption
  }

  return {
    companyEconomicResults,
    economicPreviewTable,

    createBarChartOptions,
    createLineChartOptions,
  }
}
